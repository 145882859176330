form {
  display: flex;
  margin-top: 20px;
  width: 80%;
  align-items: stretch;
}

.input-wrapper {
  position: relative;
  flex-grow: 1;
  margin-right: 10px;
  min-width: 0;
}

.input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-sizing: border-box;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: #F2F2F2;
  font-family: Arial, sans-serif;
}

header {
  margin-bottom: 20px;
}

.chat-box {
  width: 80%;
  height: 60vh;
  border: 1px solid #ddd;
  padding: 20px;
  overflow-y: auto;
  background: #fff;
  box-shadow: 0px 10px 20px rgba(0,0,0,0.19), 0px 6px 6px rgba(0,0,0,0.23);
}

.chat-message {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
}

.chat-message.User {
  align-self: flex-end;
  background: #007BFF;
  color: #fff;
}

.chat-message.Bot {
  align-self: flex-start;
  background: #E0E0E0;
}

form {
  display: flex;
  margin-top: 20px;
  width: 80%;
}

input {
  flex-grow: 1;
  padding: 10px;
  margin-right: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

button {
  padding: 10px 20px;
  min-width: 80px;  /* adjust this value to fit your needs */
  white-space: nowrap; /* prevent the label from breaking into multiple lines */
  border: none;
  border-radius: 5px;
  background: #007BFF;
  color: #fff;
  cursor: pointer;
  transition: background 0.3s;
}

button:hover {
  background: #0056b3;
}

@keyframes dot-flashing {
  0%, 100% { opacity: 0; }
  50% { opacity: 1; }
}

.input-wrapper {
  position: relative;
  width: 100%;
}

.input {
  width: 100%;
}

.loading-message {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-message span {
  display: inline-block;
  width: 6px;
  height: 6px;
  background-color: #000;
  border-radius: 50%;
  opacity: 0;
  animation: dot-flashing 1s linear infinite;
}

.loading-message span:nth-child(2) {
  animation-delay: 0.2s;
}

.loading-message span:nth-child(3) {
  animation-delay: 0.4s;
}
